import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button, TextField, Grid } from '@mui/material';
import { FieldArray, Formik } from 'formik';
import Autocomplete from '@mui/material/Autocomplete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { Instructions } from '../Instructions';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Slider from '@mui/material/Slider';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/system';

const CustomSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase': {
    color: '#3F50B5',
    '&.Mui-checked': {
      color: '#3F50B5',
    },
    '&.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#3F50B5',
    },
  },
}));

export const MapControls = ({
  makeRouteCoordinates_,
  getNearestGarage_,
  suggestions_,
  setSuggestions,
  handleSetDirections,
  routes,
  mapLeft,
  mapRight,
  parkingRoutes,
  backRoutes,
  coordinates_resp,
  iterateArray,
  stopAutomation,
  categories,
  setSelectedCategories,
  selectedCategories,
  setRange,
  setBestOnly,
  range,
  bestOnly,
}: any) => {
  const handleChange = (event: React.SyntheticEvent | any, item: any) => {
    let categories = [...selectedCategories];
    let index = categories.findIndex(category => item.alias === category);
    if (event.target.checked) {
      if (index === -1) categories.push(item.alias);
    } else {
      if (index !== -1) {
        categories.splice(index, 1);
      }
    }
    setSelectedCategories([...categories]);
  };

  const handleRange = (event: any) => {
    console.log({ range: event.target.value });
    if (event.target.value) setRange(event.target.value);
  };
  const toggleBestOnly = (event: any) => {
    console.log({ check: event.target.checked });
    setBestOnly(event.target.checked);
  };
  return (
    <Box
      sx={{
        position: 'absolute',
        left: '50px',
        top: '20px',
        width: '300px',
        zIndex: 10,
      }}
    >
      <Accordion sx={{ maxHeight: '350px', overflow: 'auto' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
        >
          <Typography>Enter Waypoint</Typography>
        </AccordionSummary>
        <Formik
          initialValues={{
            dropOffs: [
              { label: '', coordinates: [] },
              { label: '', coordinates: [] },
            ],
          }}
          enableReinitialize
          onSubmit={async values => {
            handleSetDirections(values.dropOffs);
            await makeRouteCoordinates_(values.dropOffs);
            await getNearestGarage_(
              values.dropOffs[values.dropOffs.length - 1].coordinates.toString(),
            );
          }}
          render={formik => (
            <form
              onSubmit={formik.handleSubmit}
              // style={{ maxHeight: '500px', background: 'red', overflow: 'auto' }}
            >
              <FieldArray
                name={'dropOffs'}
                render={arrayHelpers => {
                  const { dropOffs } = formik.values;
                  const isRemoveAllowed = dropOffs.length > 2;

                  return (
                    <>
                      {dropOffs.map((item: any, index: number) => {
                        const isFirst = index === 0;
                        const isLast = dropOffs.length - 1 === index;
                        const fieldLabel = isFirst ? 'Start' : isLast ? 'End' : 'Drop Off';
                        return (
                          <AccordionDetails key={index}>
                            <Autocomplete
                              freeSolo
                              onInputChange={async (event, newInputValue) => {
                                const search = `https://api.mapbox.com/geocoding/v5/mapbox.places/${newInputValue}.json?access_token=${[
                                  process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
                                ]}`;
                                const response = await fetch(search, {
                                  method: 'GET',
                                }).then(response => response.json());
                                setSuggestions(
                                  response.features.map((item: any, index: number) => ({
                                    label: item.place_name,
                                    id: index,
                                    coordinates: item.geometry.coordinates,
                                  })),
                                );
                              }}
                              onChange={(event, newValue) => {
                                arrayHelpers.replace(
                                  index,
                                  newValue || { label: '', coordinates: [] },
                                );
                              }}
                              id={`dropff-${index}`}
                              options={suggestions_}
                              fullWidth
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  fullWidth
                                  name={`dropOffs.${index}`}
                                  value={item.label}
                                  required
                                  label={fieldLabel}
                                />
                              )}
                            />
                          </AccordionDetails>
                        );
                      })}
                      <AccordionDetails>
                        <div>
                          <AddCircleIcon
                            onClick={() => arrayHelpers.push({ label: '', coordinates: [] })}
                          />
                          {isRemoveAllowed && (
                            <RemoveCircleIcon onClick={() => arrayHelpers.pop()} />
                          )}
                        </div>

                        <Box>
                          <Grid container>
                            <Grid
                              item
                              xs={12}
                              sx={{ display: 'flex', justifyContent: 'start', pb: 1 }}
                            >
                              <Typography variant='body1'>Select Categories</Typography>
                            </Grid>
                            {categories?.map((item: any, index: number) => {
                              return (
                                <Grid
                                  item
                                  xs={6}
                                  key={index}
                                  sx={{ display: 'flex', justifyContent: 'start', pb: 1 }}
                                >
                                  <FormControlLabel
                                    onChange={e => handleChange(e, item)}
                                    control={
                                      <Checkbox
                                        size='small'
                                        sx={{
                                          color: '#3F50B5',
                                          '&.Mui-checked': {
                                            color: '#3F50B5',
                                          },
                                          '.MuiCheckbox-root': {},
                                        }}
                                      />
                                    }
                                    label={
                                      <Typography variant='body1' style={{ fontSize: '12px' }}>
                                        {item.title}
                                      </Typography>
                                    }
                                  />
                                </Grid>
                              );
                            })}
                          </Grid>
                          <Grid container>
                            <Typography variant='body1'>Adjust Range</Typography>
                          </Grid>
                          <Grid container sx={{ px: '10px' }}>
                            <Slider
                              defaultValue={0.1}
                              step={0.1}
                              min={0.1}
                              max={2.5}
                              onChange={handleRange}
                              valueLabelDisplay='auto'
                              value={range}
                              sx={{
                                '&.MuiSlider-root': {
                                  color: '#3F50B5',
                                },
                              }}
                            />
                          </Grid>

                          <Grid container>
                            <Typography variant='body1'>Enable Best</Typography>
                          </Grid>
                          <Grid container sx={{}}>
                            <CustomSwitch onChange={toggleBestOnly} value={bestOnly} />
                          </Grid>
                        </Box>
                        <Button
                          disabled={formik.isSubmitting}
                          variant='contained'
                          style={{ background: '#3f50b5', color: 'white' }}
                          type={'submit'}
                        >
                          Confirm
                        </Button>
                      </AccordionDetails>
                    </>
                  );
                }}
              />
            </form>
          )}
        />
      </Accordion>
      {routes && (
        <Accordion sx={{ maxHeight: '300px', overflow: 'auto' }} defaultExpanded={true}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel2a-content'
            id='panel2a-header'
          >
            <Typography>Route Details</Typography>
          </AccordionSummary>

          <Instructions
            routes={routes}
            mapLeft={mapLeft}
            mapRight={mapRight}
            parkingRoutes={parkingRoutes}
            backRoutes={backRoutes}
            coordinates_resp={coordinates_resp}
            iterateArray={iterateArray}
            stopAutomation={stopAutomation}
          />
        </Accordion>
      )}
    </Box>
  );
};
