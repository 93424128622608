export const FormatDuration = (seconds: number) => {
  var days = Math.floor(seconds / (24 * 60 * 60));
  var hours = Math.floor((seconds % (24 * 60 * 60)) / (60 * 60));
  var minutes = Math.floor((seconds % (60 * 60)) / 60);
  var remainingSeconds = seconds % 60;

  var formattedTime = '';
  if (days > 0) {
    formattedTime += days + ' days ';
  }
  if (hours > 0) {
    formattedTime += hours + ' hr ';
  }
  if (minutes > 0) {
    formattedTime += minutes + ' min ';
  }

  return formattedTime.trim();
};

export const FormatDistance = (meters: number) => {
  return (meters / 1000).toFixed(2) + ' km'; // Two decimal places for numbers with decimals
};
