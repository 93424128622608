import * as React from 'react';
import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  useMediaQuery,
} from '@mui/material';
import {
  Menu as MenuIcon,
  Login as LoginIcon
} from '@mui/icons-material';
import { navItems } from 'common/utils';
import logo from '../../../assets/Nvg8-Logo.svg';

export const Navbar: React.FC = () => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <List>
      {navItems.map((item, index) => (
        <ListItem button key={index}>
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText primary={item.text} />
        </ListItem>
      ))}
    </List>
  );

  return (
    <div>
      <AppBar position="static">
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
          {isMobile ? (
            <>
              <IconButton color="inherit" edge="start" onClick={handleDrawerToggle}>
                <MenuIcon />
              </IconButton>
              <a href='/' >
                <img alt='logo' src={logo} style={{width:'8rem', height:'auto', filter:"invert(100%)" }}/>
              </a>
              <IconButton color="inherit" edge="end" >
                <LoginIcon />
              </IconButton>
            </>
          ) : (
            <>
              <a href='/' style={{position:'absolute', top:20, left:20}}>
                <img alt='logo' src={logo} style={{width:'8rem', height:'auto', filter:"invert(100%)"}}/>
              </a>
              <div className="desktop-nav" style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                {navItems.map((item, index) => (
                  <Button color="inherit" key={index}>
                    {item.text}
                  </Button>
                ))}
              </div>
              <IconButton color="inherit" edge="end" >
                <LoginIcon />
              </IconButton>


            </>
          )}
        </Toolbar>
      </AppBar>
      <div className="mobile-nav">
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          anchor="left"
        >
          {drawer}
        </Drawer>
      </div>
    </div>
  );
};
