import * as React from 'react';
import './App.css';
import { Navbar, Footer } from 'common/components';
import {DashboardV2Page } from './pages';

export const App: React.FC = () => {
  return (
    <div className='App'>
      <Navbar />
      <div className="App-header">
        <DashboardV2Page/>
      </div>
      <Footer />
    </div>
  );
}
