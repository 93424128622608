import React, { useRef, useEffect, useState } from 'react';
// eslint-disable-line import/no-webpack-loader-syntax
import mapboxgl, { LngLatLike } from 'mapbox-gl';
// @ts-ignore
import MapboxDirections from '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions';
// @ts-ignore
// import Compare from 'mapbox-gl-compare';
import { MapControls } from 'common/components';
import {
  getNearestGarage,
  makeRouteCoordinates,
  getSelectedGaragePath,
  getAllCategories,
  getBusinessPoints,
} from 'common/utils';
import * as turf from '@turf/turf';
import barberIcon from 'assets/business/barber.png';
import beerIcon from 'assets/business/beer.png';
import convenience_store from 'assets/business/convinience_store.png';
import car_wash from 'assets/business/car_wash.png';
import gas_station from 'assets/business/gas_station.png';
import gym from 'assets/business/gym.png';
import park from 'assets/business/park.png';
import parking from 'assets/business/parking.png';
import parking_sign from 'assets/business/parking_sign.png';
import pin from 'assets/business/pin.png';
import placeholder from 'assets/business/placeholder.png';
import restaurant from 'assets/business/restaurant.png';
import toilets from 'assets/business/toilets.png';

export const DashboardV2Page = () => {
  const mapContainer = useRef<any>(null);
  const mapRight = useRef<any>(null);
  const mapLeft = useRef<any>(null);
  const stopAutomation = React.useRef(false);
  const rightDirectionsRef = useRef<any>(null);
  const leftDirectionsRef = useRef<any>(null);
  const customMarkerRef = useRef<any>(null);
  const customMarkerRef2 = useRef<any>(null);

  const [lng, setLng] = useState(-74.006);
  const [lat, setLat] = useState(40.7128);
  const [zoom, setZoom] = useState(16);

  const [suggestions, setSuggestions] = React.useState<DropOffs[]>([]);
  const [nearestGarage, setNearestGarage] = React.useState<any>();
  const [garagePath, setGaragePath] = React.useState<any>();
  const [coordinates_resp, setCoordinates_resp] = React.useState<any>(null);
  const [routes, setRoutes] = useState(null);
  const [parkingRoutes, setParkingRoutes] = useState(null);
  const [allPossibleParkings, setAllPossibleParkings] = useState<any>(null);
  const [allMarkers, setAllMarkers] = useState<Array<any>>([]);
  const [backRoutes, setBackRoutes] = useState(null);
  const [garageToPath, setGaragePathToDestinationPath] = useState<any>();
  const [dropOffs, setDropOffs] = useState<any>([]);
  const [temperature, setTemperature] = useState<string>('');
  const [allPaths, setAllPaths] = useState<Array<any>>([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [businessPoints, setBusinessPoints] = useState(null);
  const [range, setRange] = useState(0.1);
  const [bestOnly, setBestOnly] = useState(false);

  const profile = 'mapbox/driving';

  const [viewPort, setViewPort] = useState<any>({});
  const [geoJson, setGeoJson] = useState<any>();

  async function makeRouteCoordinates_(dropOffs: DropOffs[]) {
    return makeRouteCoordinates(
      dropOffs,
      profile,
      setCoordinates_resp,
      setViewPort,
      setRoutes,
      selectedCategories,
      setBusinessPoints,
      range,
      bestOnly,
    );
  }

  async function getNearestGarage_(point: any) {
    return getNearestGarage(
      point,
      setNearestGarage,
      setGaragePath,
      setParkingRoutes,
      setAllPossibleParkings,
      setBackRoutes,
      setGaragePathToDestinationPath,
      setTemperature,
    );
  }

  const handleSetDirections = async (dropOffs: Array<any>) => {
    const dropOffsCount = dropOffs.length;
    setDropOffs(dropOffs);
    removeMarker();
    if (dropOffsCount < 2) {
      return;
    } else {
      const origin = dropOffs[0]?.coordinates;
      const destination = dropOffs[dropOffsCount - 1]?.coordinates;
      let waypoint = {};
      const geoJson: any = {
        type: 'FeatureCollection',
        features: [],
      };
      const features = [];
      let obj: any = {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'Point',
          coordinates: [],
        },
      };
      if (dropOffsCount > 2) {
        for (const [index, dropOff] of dropOffs.entries() as any) {
          if (index === 0) {
            // await rightDirectionsRef?.current?.setOrigin(origin);
            // await leftDirectionsRef?.current?.setOrigin(origin);
            if (Array.isArray(origin)) {
              features.push({
                ...obj,
                properties: { ...obj.properties, label: 'A' },
                geometry: {
                  ...obj.geometry,
                  coordinates: [...origin],
                },
              });
            }
          } else if (index === dropOffsCount - 1) {
            // await rightDirectionsRef?.current?.setDestination(destination);
            // await leftDirectionsRef?.current?.setDestination(destination);
            if (Array.isArray(destination)) {
              features.push({
                ...obj,
                properties: { ...obj.properties, label: 'B' },

                geometry: {
                  ...obj.geometry,
                  coordinates: [...destination],
                },
              });
            }
          } else {
            waypoint = {
              geometry: { type: 'Point', coordinates: [...dropOff?.coordinates] },
              properties: { id: 'waypoint' },
              type: 'Feature',
            };
            // await rightDirectionsRef?.current?.addWaypoint(index - 1, { ...waypoint });
            // await leftDirectionsRef?.current?.addWaypoint(index - 1, { ...waypoint });
            // obj.geometry.coordinates = [...dropOff?.coordinates];
            // features.push(obj);
            if (Array.isArray(dropOff?.coordinates)) {
              features.push({
                ...obj,
                properties: { ...obj.properties, label: index },

                geometry: {
                  ...obj.geometry,
                  coordinates: [...dropOff?.coordinates],
                },
              });
            }
            // new mapboxgl.Marker()
            //   .setLngLat([...dropOff?.coordinates] as LngLatLike)
            //   .addTo(mapLeft.current);
            // new mapboxgl.Marker()
            //   .setLngLat([...dropOff?.coordinates] as LngLatLike)
            //   .addTo(mapRight.current);
          }
        }
      } else {
        // rightDirectionsRef?.current?.setDestination(destination);
        if (Array.isArray(destination)) {
          features.push({
            ...obj,
            properties: { ...obj.properties, label: 'B' },

            geometry: {
              ...obj.geometry,
              coordinates: [...destination],
            },
          });
        }
        // rightDirectionsRef?.current?.setOrigin(origin);
        // leftDirectionsRef?.current?.setDestination(destination);
        // leftDirectionsRef?.current?.setOrigin(origin);

        if (Array.isArray(origin)) {
          features.push({
            ...obj,
            properties: { ...obj.properties, label: 'A' },

            geometry: {
              ...obj.geometry,
              coordinates: [...origin],
            },
          });
        }
      }
      geoJson.features = [...features];

      setGeoJson(geoJson);

      if (mapRight.current.getSource('nvg8-route-points')) {
        mapRight.current.getSource('nvg8-route-points').setData(geoJson);
      } else {
        mapRight.current.addSource('nvg8-route-points', {
          type: 'geojson',
          data: geoJson,
        });

        mapRight.current.addLayer({
          id: 'nvg8-route-points-layer',
          type: 'circle',
          source: 'nvg8-route-points',
          paint: {
            'circle-radius': 25,
            'circle-color': '#3BB2D0',
          },
        });

        mapRight.current.addLayer({
          id: 'nvg8-route-label',
          type: 'symbol',
          source: 'nvg8-route-points',
          layout: {
            'text-field': '{label}',
            'text-size': 12,
          },
        });
      }

      if (mapLeft.current.getSource('nvg8-route-points')) {
        mapLeft.current.getSource('nvg8-route-points').setData(geoJson);
      } else {
        mapLeft.current.addSource('nvg8-route-points', {
          type: 'geojson',
          data: geoJson,
        });

        mapLeft.current.addLayer({
          id: 'nvg8-route-points-layer',
          type: 'circle',
          source: 'nvg8-route-points',
          paint: {
            'circle-radius': 25,
            'circle-color': '#3BB2D0',
          },
        });

        mapLeft.current.addLayer({
          id: 'nvg8-route-label',
          type: 'symbol',
          source: 'nvg8-route-points',
          layout: {
            'text-field': '{label}',
            'text-size': 12,
          },
        });
      }
    }
  };

  function updateTextField(newText: any) {
    if (dropOffs && dropOffs.length) {
      const data = { ...geoJson };
      const index = geoJson?.features?.length === 2 ? 0 : geoJson?.features?.length - 1;
      const feature = geoJson.features[index];
      if (feature?.properties) {
        feature.properties.label = `B\n${newText}`;
        data.features[index] = feature;
      }
      mapRight.current.getSource('nvg8-route-points').setData({ ...data });
      mapLeft.current.getSource('nvg8-route-points').setData({ ...data });
    }
  }

  useEffect(() => {
    if (geoJson && temperature) {
      updateTextField(`${temperature}°F`);
    }
  }, [temperature, geoJson]);

  const addNearestGarageLayer = () => {
    if (mapRight.current.getSource('nvg8-garage')) {
      mapRight.current.getSource('nvg8-garage').setData(nearestGarage);
    } else {
      mapRight.current.addSource('nvg8-garage', {
        type: 'geojson',
        data: nearestGarage,
      });

      mapRight.current.addLayer(
        {
          id: 'nvg8-garage-layer',
          type: 'circle',
          source: 'nvg8-garage',
          paint: {
            'circle-radius': 10,
            'circle-color': '#00e673',
          },
        },
        'nvg8-route-points-layer',
      );

      mapRight.current.addLayer(
        {
          id: 'nvg8-parking-layer',
          type: 'symbol',
          source: 'nvg8-garage',
          layout: {
            'text-field': 'Parking', // The text to display
            'text-offset': [0, 1.5], // Offset the text upwards by 1.5 units
            'text-anchor': 'center', // Center the text on the coordinate
          },
          paint: {
            'text-color': '#00e673', // Set the text color to blue
          },
        },
        'nvg8-route-points-layer',
      );
    }

    if (mapLeft.current.getSource('nvg8-garage')) {
      mapLeft.current.getSource('nvg8-garage').setData(nearestGarage);
    } else {
      mapLeft.current.addSource('nvg8-garage', {
        type: 'geojson',
        data: nearestGarage,
      });

      mapLeft.current.addLayer(
        {
          id: 'nvg8-garage-layer',
          type: 'circle',
          source: 'nvg8-garage',
          paint: {
            'circle-radius': 10,
            'circle-color': '#00e673',
          },
        },
        'nvg8-route-points-layer',
      );
      mapLeft.current.addLayer(
        {
          id: 'nvg8-parking-layer',
          type: 'symbol',
          source: 'nvg8-garage',
          layout: {
            'text-field': 'Parking', // The text to display
            'text-offset': [0, 1.5], // Offset the text upwards by 1.5 units
            'text-anchor': 'center', // Center the text on the coordinate
          },
          paint: {
            'text-color': '#00e673', // Set the text color to blue
          },
        },
        'nvg8-route-points-layer',
      );
    }
  };

  const addNearestGaragePathLayer = () => {
    if (mapRight.current.getSource('nvg8-garage-path')) {
      mapRight.current.getSource('nvg8-garage-path').setData(garagePath);
    } else {
      mapRight.current.addSource('nvg8-garage-path', {
        type: 'geojson',
        data: garagePath,
      });

      mapRight.current.addLayer(
        {
          id: 'nvg8-garage-path-layer',
          type: 'line',
          source: 'nvg8-garage-path',
          paint: {
            'line-color': '#00e673',
            'line-width': 8,
          },
        },
        'nvg8-route-points-layer',
      );
    }

    if (mapLeft.current.getSource('nvg8-garage-path')) {
      mapLeft.current.getSource('nvg8-garage-path').setData(garagePath);
    } else {
      mapLeft.current.addSource('nvg8-garage-path', {
        type: 'geojson',
        data: garagePath,
      });

      mapLeft.current.addLayer(
        {
          id: 'nvg8-garage-path-layer',
          type: 'line',
          source: 'nvg8-garage-path',
          paint: {
            'line-color': '#00e673',
            'line-width': 8,
          },
        },
        'nvg8-route-points-layer',
      );
    }
  };

  const handleAddRoutesLayer = () => {
    if (mapRight.current.getSource('nvg8-routes')) {
      mapRight.current.getSource('nvg8-routes').setData(coordinates_resp);
    } else {
      mapRight.current.addSource('nvg8-routes', {
        type: 'geojson',
        data: coordinates_resp,
      });

      mapRight.current.addLayer(
        {
          id: 'nvg8-routes-layer',
          type: 'line',
          source: 'nvg8-routes',
          paint: {
            'line-color': '#366AA7',
            'line-width': 8,
          },
        },
        'nvg8-route-points-layer',
      );
    }

    if (mapLeft.current.getSource('nvg8-routes')) {
      mapLeft.current.getSource('nvg8-routes').setData(coordinates_resp);
    } else {
      mapLeft.current.addSource('nvg8-routes', {
        type: 'geojson',
        data: coordinates_resp,
      });

      mapLeft.current.addLayer(
        {
          id: 'nvg8-routes-layer',
          type: 'line',
          source: 'nvg8-routes',
          paint: {
            'line-color': '#366AA7',
            'line-width': 8,
          },
        },
        'nvg8-route-points-layer',
      );
    }
  };

  const _getBusinessPoints = async () => {
    const businessPoints = await getBusinessPoints(
      coordinates_resp,
      selectedCategories,
      range,
      bestOnly,
    );
    if (businessPoints?.data) setBusinessPoints(businessPoints?.data);
  };

  const _getAllCategories = async () => {
    const categories = await getAllCategories();
    if (categories?.categories) setCategories(categories?.categories);
  };

  // Animate the marker from initialCoordinates to finalCoordinates
  function animateMarker(
    initialCoordinates: any,
    finalCoordinates: any,
    duration: number,
    cb: Function,
  ) {
    if (document.getElementById('custom-marker')) {
      customMarkerRef.current.setLngLat(initialCoordinates);
    } else {
      var el = document.createElement('div');
      el.className = 'custom-marker';
      el.id = 'custom-marker';
      var marker = new mapboxgl.Marker(el).setLngLat(initialCoordinates).addTo(mapLeft.current);
      customMarkerRef.current = marker;
    }

    if (document.getElementById('custom-marker-2')) {
      customMarkerRef2.current.setLngLat(initialCoordinates);
    } else {
      var el2 = document.createElement('div');
      el2.className = 'custom-marker';
      el2.id = 'custom-marker-2';
      var marker2 = new mapboxgl.Marker(el2).setLngLat(initialCoordinates).addTo(mapRight.current);
      customMarkerRef2.current = marker2;
    }
    // Create a marker with the custom element

    customMarkerRef.current.setLngLat(initialCoordinates);
    customMarkerRef.current.addTo(mapLeft.current);

    customMarkerRef2.current.setLngLat(initialCoordinates);
    customMarkerRef2.current.addTo(mapRight.current);

    var start: any = null;

    function moveMarker(timestamp: any) {
      var progress = (timestamp - start) / duration;
      if (progress > 1) progress = 1;
      var lng = initialCoordinates[0] + progress * (finalCoordinates[0] - initialCoordinates[0]);
      var lat = initialCoordinates[1] + progress * (finalCoordinates[1] - initialCoordinates[1]);
      console.log({ lng, lat, initialCoordinates, finalCoordinates, progress, duration });

      customMarkerRef.current.setLngLat([lng, lat]);
      customMarkerRef2.current.setLngLat([lng, lat]);

      mapLeft.current.setCenter([lng, lat]);
      mapRight.current.setCenter([lng, lat]);

      var bearing = turf.bearing(
        turf.point([initialCoordinates[0], initialCoordinates[1]]),
        turf.point([finalCoordinates[0], finalCoordinates[1]]),
      );

      mapLeft.current.setBearing(bearing);
      mapRight.current.setBearing(bearing);
      if (stopAutomation.current) {
        cb();
        return;
      } else {
        if (progress < 1) {
          requestAnimationFrame(moveMarker);
        }
      }
    }

    requestAnimationFrame(function (timestamp) {
      start = timestamp;
      moveMarker(timestamp);
    });
  }

  const removeMarker = () => {
    if (customMarkerRef?.current) customMarkerRef.current.remove();
    if (customMarkerRef2?.current) customMarkerRef2.current.remove();
  };
  let index = 0;
  function iterateArray(array: any, cb: Function) {
    if (index < allPaths.length) {
      if (stopAutomation.current) {
        cb();
        return;
      } else {
        let _initialCoordinates: any = allPaths[index];
        let _finalCoordinates: any = allPaths[index + 1];
        var duration = 2000; // Animation duration in milliseconds
        if (_finalCoordinates) {
          var distance = turf.distance(
            turf.point(_initialCoordinates),
            turf.point(_finalCoordinates),
            { units: 'meters' }, // You can adjust units based on your preference
          );
          if (distance) duration = distance * 100;
          else duration = 1;
          console.log({ distance });
        }
        if (allPaths[index + 1]) {
          animateMarker(allPaths[index], allPaths[index + 1], duration, cb);
        }
        index++;

        setTimeout(() => iterateArray(allPaths, cb), duration); // Wait for {duration}  seconds before the next iteration
      }
    } else {
      index = 0;
      cb();
    }
  }

  useEffect(() => {
    _getAllCategories();
  }, []);
  useEffect(() => {
    if (coordinates_resp) {
      let array: Array<any> = [];
      let coords = coordinates_resp.geometry.coordinates;
      if (coords) {
        array = [...coordinates_resp.geometry.coordinates];
        setAllPaths([...array]);
      }
    }
  }, [coordinates_resp]);

  useEffect(() => {
    if (garagePath) {
      let array: Array<any> = [];

      let coords = garagePath.geometry.coordinates;
      if (coords) {
        array = [...coords];
        setAllPaths(_array => [..._array, ...array]);
      }
    }
  }, [garagePath]);

  useEffect(() => {
    if (garageToPath) {
      let array: Array<any> = [];

      let coords = garageToPath.geometry.coordinates;
      if (coords) {
        array = [...coords];
        setAllPaths(_array => [..._array, ...array]);
      }
    }
  }, [garageToPath]);

  useEffect(() => {
    if (mapLeft.current || mapRight.current) return; // initialize map only once

    mapLeft.current = new mapboxgl.Map({
      container: 'left',
      accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
      style: 'mapbox://styles/mapbox/streets-v12',
      center: [lng, lat],
      pitch: 80,
      bearing: 100,
      zoom: zoom,
    });

    mapRight.current = new mapboxgl.Map({
      container: 'right',
      accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
      style: 'mapbox://styles/mapbox/dark-v11',
      center: [lng, lat],
      zoom: zoom,
    });

    // const map = new Compare(mapLeft.current, mapRight.current, mapContainer.current, {
    //   // Set this to enable comparing two maps by mouse movement:
    //   // mousemove: true
    // });
    const directions: any = new MapboxDirections({
      accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
      unit: 'metric',
      profile: 'mapbox/driving',
      controls: {
        instructions: true,
        profileSwitcher: true,
      },
    });

    const leftDirections: any = new MapboxDirections({
      accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
      unit: 'metric',
      profile: 'mapbox/driving',
      controls: {
        instructions: true,
        profileSwitcher: true,
      },
    });
    // map._mapA.addControl(directions, 'top-left');

    // map._mapB.addControl(leftDirections, 'top-left');

    rightDirectionsRef.current = directions;
    leftDirectionsRef.current = leftDirections;

    mapLeft.current.on('style.load', () => {
      const layers = mapLeft.current.getStyle().layers;
      const labelLayerId = layers.find(
        (layer: any) => layer.type === 'symbol' && layer.layout['text-field'],
      ).id;

      loadImages(mapLeft);
      mapLeft.current.addLayer(
        {
          id: 'add-3d-buildings',
          source: 'composite',
          'source-layer': 'building',
          filter: ['==', 'extrude', 'true'],
          type: 'fill-extrusion',
          minzoom: 15,
          paint: {
            'fill-extrusion-color': [
              'match',
              ['get', 'type'],
              'parking',
              '#0000ff', // Blue for parking
              'garage',
              '#0000ff', // Blue for parking
              '#aaa', // Default color for other building types
            ],
            'fill-extrusion-height': [
              'interpolate',
              ['linear'],
              ['zoom'],
              15,
              0,
              15.05,
              ['get', 'height'],
            ],
            'fill-extrusion-base': [
              'interpolate',
              ['linear'],
              ['zoom'],
              15,
              0,
              15.05,
              ['get', 'min_height'],
            ],
            'fill-extrusion-opacity': 0.6,
          },
        },
        labelLayerId,
      );
    });

    mapRight.current.on('style.load', () => {
      loadImages(mapRight);
    });
  });

  const loadImages = (map: any) => {
    map.current.loadImage(beerIcon, async (error: any, image: any) => {
      if (error) {
        console.log(error);
      }

      if (!map.current.hasImage('beer-icon')) await map.current.addImage('beer-icon', image);
    });

    map.current.loadImage(barberIcon, async (error: any, image: any) => {
      if (error) {
        console.log(error);
        return;
      }
      if (!map.current.hasImage('barber')) await map.current.addImage('barber', image);
    });

    map.current.loadImage(convenience_store, async (error: any, image: any) => {
      if (error) {
        console.log(error);
        return;
      }
      if (!map.current.hasImage('convenience_store'))
        await map.current.addImage('convenience_store', image);
    });

    map.current.loadImage(car_wash, async (error: any, image: any) => {
      if (error) {
        console.log(error);
        return;
      }
      if (!map.current.hasImage('car_wash')) await map.current.addImage('car_wash', image);
    });

    map.current.loadImage(gas_station, async (error: any, image: any) => {
      if (error) {
        console.log(error);
        return;
      }
      if (!map.current.hasImage('gas_station')) await map.current.addImage('gas_station', image);
    });

    map.current.loadImage(gym, async (error: any, image: any) => {
      if (error) {
        console.log(error);
        return;
      }
      if (!map.current.hasImage('gym')) await map.current.addImage('gym', image);
    });

    map.current.loadImage(park, async (error: any, image: any) => {
      if (error) {
        console.log(error);
        return;
      }
      if (!map.current.hasImage('park-icon')) await map.current.addImage('park-icon', image);
    });
    map.current.loadImage(parking, async (error: any, image: any) => {
      if (error) {
        console.log(error);
        return;
      }
      if (!map.current.hasImage('parking-icon')) await map.current.addImage('parking-icon', image);
    });
    map.current.loadImage(parking_sign, async (error: any, image: any) => {
      if (error) {
        console.log(error);
        return;
      }
      if (!map.current.hasImage('parking_sign')) await map.current.addImage('parking_sign', image);
    });

    map.current.loadImage(pin, async (error: any, image: any) => {
      if (error) {
        console.log(error);
        return;
      }
      if (!map.current.hasImage('pin')) await map.current.addImage('pin', image);
    });

    map.current.loadImage(placeholder, async (error: any, image: any) => {
      if (error) {
        console.log(error);
        return;
      }
      if (!map.current.hasImage('placeholder')) await map.current.addImage('placeholder', image);
    });
    map.current.loadImage(restaurant, async (error: any, image: any) => {
      if (error) {
        console.log(error);
        return;
      }
      if (!map.current.hasImage('restaurant-icon'))
        await map.current.addImage('restaurant-icon', image);
    });

    map.current.loadImage(toilets, async (error: any, image: any) => {
      if (error) {
        console.log(error);
        return;
      }
      if (!map.current.hasImage('toilets')) await map.current.addImage('toilets', image);
    });
  };
  const addNearestGaragePathBackLayer = () => {
    if (mapRight.current.getSource('nvg8-destination-path')) {
      mapRight.current.getSource('nvg8-destination-path').setData(garageToPath);
    } else {
      mapRight.current.addSource('nvg8-destination-path', {
        type: 'geojson',
        data: garageToPath,
      });

      mapRight.current.addLayer(
        {
          id: 'nvg8-destination-path-layer',
          type: 'line',
          source: 'nvg8-destination-path',
          paint: {
            'line-color': 'red',
            'line-width': 8,
          },
        },
        'nvg8-route-points-layer',
      );
    }

    if (mapLeft.current.getSource('nvg8-destination-path')) {
      mapLeft.current.getSource('nvg8-destination-path').setData(garageToPath);
    } else {
      mapLeft.current.addSource('nvg8-destination-path', {
        type: 'geojson',
        data: garageToPath,
      });

      mapLeft.current.addLayer(
        {
          id: 'nvg8-destination-path-layer',
          type: 'line',
          source: 'nvg8-destination-path',
          paint: {
            'line-color': 'red',
            'line-width': 8,
          },
        },
        'nvg8-route-points-layer',
      );
    }
  };

  useEffect(() => {
    if (garageToPath) {
      addNearestGaragePathBackLayer();
    }
  }, [garageToPath]);

  useEffect(() => {
    if (nearestGarage) {
      addNearestGarageLayer();
    }
  }, [nearestGarage]);

  useEffect(() => {
    if (garagePath) {
      addNearestGaragePathLayer();
    }
  }, [garagePath]);

  useEffect(() => {
    if (coordinates_resp) {
      handleAddRoutesLayer();
    }
  }, [coordinates_resp]);

  useEffect(() => {
    if (viewPort && Object.keys(viewPort)?.length && mapLeft?.current) {
      mapLeft.current.flyTo({
        center: [viewPort.longitude, viewPort.latitude],
        zoom: viewPort.zoom,
        bearing: viewPort.bearing,
      });
    }

    if (viewPort && Object.keys(viewPort)?.length && mapRight?.current) {
      mapRight.current.flyTo({
        center: [viewPort.longitude, viewPort.latitude],
        zoom: viewPort.zoom,
        bearing: viewPort.bearing,
      });
    }
  }, [viewPort, mapLeft, mapRight]);

  useEffect(() => {
    if (allPossibleParkings) {
      addMarkers();
    }
  }, [allPossibleParkings]);

  const addMarkers = async () => {
    const features = allPossibleParkings?.features;
    let markers: any = [...allMarkers];

    for (const marker of allMarkers) {
      await marker.remove();
    }
    markers = [];
    let marker1, marker2;
    if (!markers.length) {
      features.map(async (feature: any, index: number) => {
        marker1 = new mapboxgl.Marker({ color: '#00e673' })
          .setLngLat(feature.geometry.coordinates)
          .addTo(mapLeft.current);
        markers.push(marker1);
        var markerLngLat = marker1.getLngLat();
        marker1.getElement().addEventListener('click', (e: any) => {
          var turfPoint = turf.point([markerLngLat.lng, markerLngLat.lat]);

          getSelectedGaragePath(
            dropOffs[dropOffs.length - 1].coordinates.toString(),
            turfPoint,
            setNearestGarage,
            setGaragePath,
            setParkingRoutes,
            setBackRoutes,
            setGaragePathToDestinationPath,
          );
        });

        marker2 = new mapboxgl.Marker({ color: '#00e673' })
          .setLngLat(feature.geometry.coordinates)
          .addTo(mapRight.current);
        markers.push(marker2);
        markerLngLat = marker2.getLngLat();
        marker2.getElement().addEventListener('click', (e: any) => {
          var turfPoint = turf.point([markerLngLat.lng, markerLngLat.lat]);

          getSelectedGaragePath(
            dropOffs[dropOffs.length - 1].coordinates.toString(),
            turfPoint,
            setNearestGarage,
            setGaragePath,
            setParkingRoutes,
            setBackRoutes,
            setGaragePathToDestinationPath,
          );
        });
      });
    }
    setAllMarkers([...markers]);
  };

  useEffect(() => {
    if (coordinates_resp) {
      _getBusinessPoints();
    }
  }, [selectedCategories, range, bestOnly]);

  useEffect(() => {
    if (businessPoints) {
      addBusinessPointsLayer();
    }
  }, [businessPoints]);

  const addBusinessPointsLayerToMap = async (map: any) => {
    if (map.current.getSource('nvg8-business-source')) {
      map.current.getSource('nvg8-business-source').setData(businessPoints);
    } else {
      map.current.addSource('nvg8-business-source', {
        type: 'geojson',
        data: businessPoints,
      });

      // map.current.addLayer({
      //   id: 'nvg8-business-layer',
      //   type: 'circle',
      //   source: 'nvg8-business-source',
      //   paint: {
      //     'circle-radius': 10,
      //     'circle-color': '#FAB938',
      //   },
      // });
      // map.current.addLayer({
      //   id: 'nvg8-business-name-layer',
      //   type: 'symbol',
      //   source: 'nvg8-business-source',
      //   layout: {
      //     'text-field': ['get', 'name'], // The text to display
      //     'text-offset': [0, 1.5], // Offset the text upwards by 1.5 units
      //     'text-anchor': 'center', // Center the text on the coordinate
      //     'text-size': 10,
      //   },
      //   paint: {
      //     'text-color': '#FAB938', // Set the text color to blue
      //   },
      // });

      map.current.addLayer(
        {
          id: 'nvg8-business-1-layer',
          type: 'symbol',
          source: 'nvg8-business-source',
          layout: {
            // 'icon-image': 'beer-icon',

            'text-field': ['get', 'name'], // The text to display
            'text-offset': [0, 1.5], // Offset the text upwards by 1.5 units
            'text-anchor': 'center', // Center the text on the coordinate
            'text-size': 10,
            'icon-image': [
              'match',
              ['get', 'category_alias'],
              'barbers',
              'barber',
              'bars',
              'beer-icon',
              'carwash',
              'car_wash',
              'convenience',
              'convenience_store',
              'evchargingstations',
              'gas_station',
              'gasstations',
              'gas_station',
              'gyms',
              'gym',
              'parking',
              'parking-icon',
              'parks',
              'park-icon',
              'restaurants',
              'restaurant-icon',
              'placeholder', // Default icon for other business types
            ],
            'icon-size': 0.05,
          },
          paint: {
            'text-color': '#FAB938', // Set the text color to blue
          },
        },
        // 'nvg8-route-points-layer',
      );
    }
  };

  const addBusinessPointsLayer = () => {
    addBusinessPointsLayerToMap(mapRight);
    addBusinessPointsLayerToMap(mapLeft);
  };

  return (
    <div
      ref={mapContainer}
      style={{
        position: 'relative',
        height: '90vh',
        overflow: 'auto',
        width: '100%',
        display: 'flex',
      }}
    >
      <div id={'left'} style={{ height: '100%', width: '50%' }}>
        <MapControls
          makeRouteCoordinates_={makeRouteCoordinates_}
          getNearestGarage_={getNearestGarage_}
          suggestions_={suggestions}
          setSuggestions={setSuggestions}
          handleSetDirections={handleSetDirections}
          routes={routes}
          parkingRoutes={parkingRoutes}
          mapLeft={mapLeft}
          mapRight={mapRight}
          backRoutes={backRoutes}
          removeMarker={removeMarker}
          coordinates_resp={coordinates_resp}
          iterateArray={iterateArray}
          stopAutomation={stopAutomation}
          categories={categories}
          setSelectedCategories={setSelectedCategories}
          selectedCategories={selectedCategories}
          setRange={setRange}
          setBestOnly={setBestOnly}
          range={range}
          bestOnly={bestOnly}
        />
      </div>
      <div id={'right'} style={{ height: '100%', width: '50%' }}></div>
    </div>
  );
};
