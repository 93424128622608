import React from 'react';
import ReactDOM from 'react-dom/client';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'mapbox-gl-compare/dist/mapbox-gl-compare.css';
import '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions.css';
import './index.css';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import { appContext } from 'common/utils';
import { store } from './common/store';
import { ThemeProvider } from '@mui/material/styles';
import { appTheme } from 'styles';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <appContext.Provider value={store}>
      <ThemeProvider theme={appTheme}>
        <App />
      </ThemeProvider>
    </appContext.Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
