import { createTheme, ThemeOptions } from '@mui/material';
export const appTheme: ThemeOptions = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      main: '#ffffff',
      light: '#ffffff',
      dark: '#000000',
    },
    secondary: {
      main: '#000000',
      light: '#ffffff',
      dark: '#000000',
    },
  },
  typography: {
    h1: {
      fontSize:'3rem',
      color:'#000000',
    },
    h2: {},
    h3: {},
    h4: {},
    h5:{
      color:'white',
    },
    h6:{
      color:'white',
    },
    body1: {},
    body2: {},
  },
  components: {
    MuiTypography:{
      styleOverrides:{
        root:{
          color: "black",
        }
      }
    },
    MuiInput:{
      styleOverrides:{
        root:{
          color: "white",
          fontSize: "20px",
          fontWeight: 500,
          "&:before": {
            borderBottom:`2px solid #7B8491`,
            transition:'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
          },
          "&:hover": {
            borderBottom:`2px solid #1976d2`,
            transition:'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
          },
          "&:after": {
            borderBottom:`2px solid #1976d2`,
            transition:'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
          },
          '&.Mui-error':{
            borderBottom:`2px solid #BC103A`,
            transition:'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
          },
          "& input::placeholder": {
              color: "white",
              fontSize: "20px",
              fontWeight: 500,
          },
        }
      }
    },
    MuiButton:{
      styleOverrides:{
        root:{
          // '&:hover': {
          //   backgroundColor: '#156b8a',
          //   opacity:'0.9',
          // },
          // '&.Mui-active': {
          //   backgroundColor: '#0f455c',
          // },
        }
      }
    }
  },
});
