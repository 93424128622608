import * as React from 'react';
import { BottomNavigation, Grid, Input, Typography } from '@mui/material';
import { SuccesButton } from '../SuccessButton';
import logo from 'assets/Nvg8-Logo.svg';
// import AppstoreIcon from 'assets/app-store.png'
import discordIcon from 'assets/discord.svg';
import fbIcon from 'assets/fb.svg';
import instaIcon from 'assets/insta.svg';
import linkedinIcon from 'assets/linkedin.svg';
import mediumIcon from 'assets/medium.svg';
import twitterIcon from 'assets/twitter.svg';
import { footerLinks } from 'common/utils';
const socialLinks = [
    { icon: twitterIcon, name: 'Twitter' },
    { icon: linkedinIcon, name: 'LinkedIn' },
    { icon: mediumIcon, name: 'Medium' },
    { icon: discordIcon, name: 'Discord' },
    { icon: instaIcon, name: 'Instagram' },
    { icon: fbIcon, name: 'Facebook' }
];

const MapFooterLinks: React.FC = () => {
    return (
        <Grid container width={"100%"} justifyContent={"center"} display={"flex"} flexDirection={'row'} rowGap={5}>
        {footerLinks.map((column: any, columnIndex: number) => {
            return (
                <Grid item key={columnIndex} xs={12} sm={6} md={2} alignSelf={"flex-start"} textAlign={"start"}>
                    <Grid container gap={3} width={"100%"}>
                        <Grid item xs={12} md={12}>
                            <Typography variant="h5" color={'white'}>{column.heading}</Typography>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            {column.values.map((link:any, linkIndex:number) => (
                                <Typography variant="body1" color={'#7B8491'} key={linkIndex}>{link}</Typography>
                            ))}
                        </Grid> 
                    </Grid>
                </Grid>
            )
        })}
        <Grid item xs={12} sm={12} md={4} alignSelf={"flex-start"} textAlign={"end"}>
            <Grid container width={"100%"} justifyContent={"end"} display={"flex"} flexDirection={'column'} gap={2} >
                <Grid item xs={12} md={12}>
                    <Typography variant="body1" color={'#7B8491'} >Download on the App Store</Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                    {/* <a href='/' >
                        <img alt='logo' src={AppstoreIcon} style={{width:'10rem', height:'auto' }}/>
                    </a> */}
                        <Typography variant="body1" color={'white'} >Coming Soon!</Typography>

                </Grid>
                <Grid item xs={12} md={12}>
                    <Typography variant="body1" color={'#7B8491'} >
                        1 Irving Place #08 – 11 The <br/>
                        Commerze@Irving <br/>
                        Singapore (369546) <br/>
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
    )
}
export const Footer: React.FC = () => {
  return (
    <BottomNavigation
      sx={{
        position: 'static',
        bottom: 0,
        left: 0,
        right: 0,
        justifyContent: 'center',
        padding:'4vh 5vw',
        height:'100%',
        alignItems:'center',
        backgroundColor:'#0c1e1f'
      }}
    >
        <Grid container width={"100%"} justifyContent={"center"} display={"flex"} flexDirection={'column'} gap={5}>
            <Grid item sm={12}>
                <Typography variant="h1" color={"white"} component="div" sx={{}}>
                    Sign up for our newsletter to stay updated on the latest Navigate news and insights
                </Typography>
            </Grid>
            <Grid item sm={12}>
                <Input placeholder="Your email address" fullWidth />
            </Grid>
            <Grid item sm={12} alignSelf={"flex-end"}>
                <SuccesButton>Sign up for our newsletter</SuccesButton>
            </Grid>
            <Grid item xs={12} md={12} alignSelf={"flex-start"}>
                <a href='/' >
                    <img alt='logo' src={logo} style={{width:'10rem', height:'auto' }}/>
                </a>
            </Grid>
            <Grid item xs={12} md={12} alignSelf={"flex-start"} width={'100%'}>
                <MapFooterLinks/>
            </Grid>
            <Grid item xs={12} md={12} alignSelf={"flex-start"} width={'100%'}>
                <Grid container width={"100%"} justifyContent={"center"} display={"flex"} flexDirection={'row'} rowGap={5} >
                    <Grid item sm={12} md={6} textAlign={"start"} alignSelf={"center"} sx={{textAlign:{sm: 'center', md:'start'}}}>
                        <Typography variant="h5" color={'white'} fontSize={"1rem"}>© 2023 Navigate Foundation, LTD. All Rights Reserved.</Typography>
                    </Grid>
                    <Grid item sm={12} md={6} textAlign={"end"} alignSelf={"flex-end"} sx={{textAlign:{sm: 'center', md:'end'}}}>
                        <Grid container display={"flex"} flexDirection={'row'} gap={2}>
                            <Grid item xs={2} sm={2} md={2} alignSelf={"center"}>
                                <Typography variant="h5" color={'white'} fontSize={"1rem"}>Social:</Typography>
                            </Grid>
                            {
                                socialLinks.map((link:any, index:number)=>{
                                    return (
                                        <Grid key={index} item xs={1} sm={1} md={1}>
                                            <a href='/' >
                                                <img alt={link.name} src={link.icon} style={{width:'2rem', height:'auto', }}/>
                                            </a>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                        
                    </Grid>
                </Grid>
            </Grid>            
            
        </Grid>
    </BottomNavigation>
  );
};
