import { apiBaseUrl, tripsBaseURL } from '../others';
import * as turf from '@turf/turf';
import axios from 'axios';

const generateRouteLine = async (points: Array<any>) => {
  let coords: any = [];
  if (points.length) {
    for (var point of points) {
      for (var step of point.steps) {
        await coords.push(...step.geometry.coordinates);
      }
    }

    const routeLine = await turf.lineString(coords);
    return { routeLine, lineCoordinates: coords };
  }
  return { routeLine: null, lineCoordinates: coords };
};

const getNearestGarageCoordinates = async (point: any) => {
  if (point) {
    const api = `${apiBaseUrl}/geocoding/v5/mapbox.places/parking.json?proximity=${point}&access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`;
    const response = await fetch(api, {
      method: 'GET',
    }).then(response => response.json());
    return response;
  }
  return;
};

export async function makeRouteCoordinates(
  dropOffs: DropOffs[],
  profile: string,
  setCoordinates_resp: any,
  setViewPort: any,
  setRoutes: Function,
  selectedCategories: Array<string>,
  setBusinessPoints: Function,
  range: number,
  bestOnly: boolean,
) {
  const startingPoint = dropOffs[0];
  const coordinates: string = dropOffs
    .reduce((acc, item) => acc + item.coordinates.toString() + ';', '')
    .slice(0, -1);
  const coordinatesjson = `${apiBaseUrl}/directions/v5/${profile}/${coordinates}?steps=true&geometries=geojson&access_token=${[
    process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
  ]}`;
  const response = await fetch(coordinatesjson, {
    method: 'GET',
  }).then(response => response.json());

  let routes = response?.routes && Array.isArray(response?.routes) && { ...response.routes[0] };

  // const routeLine = turf.lineString(response.routes[0].geometry.coordinates);
  let legs = routes?.legs;

  const { routeLine, lineCoordinates } = await generateRouteLine(legs);
  console.log({ lineCoordinates: lineCoordinates?.length });

  setRoutes({ ...routes });
  if (routeLine) setCoordinates_resp(routeLine);

  const ending = routes?.legs[0]?.steps[1]?.geometry?.coordinates[0];
  const start = routes?.legs[0]?.steps[0]?.geometry?.coordinates[0];

  var bearing = turf.bearing(turf.point([start[0], start[1]]), turf.point([ending[0], ending[1]]));

  // Set the map's bearing to point towards the destination

  setViewPort((prevState: any) => ({
    ...prevState,
    zoom: 19,
    longitude: startingPoint.coordinates[0],
    latitude: startingPoint.coordinates[1],
    bearing: bearing,
  }));
  if (routeLine) {
    console.log({ routeLine });
    if (lineCoordinates?.length > 4) {
      // const options = {
      //   tolerance: 0.0001, // Simplification tolerance (smaller tolerance means higher precision)
      // };
      // const simplifiedGeometry = turf.simplify(routeLine, options);
      // let coordinates = simplifiedGeometry?.geometry.coordinates;
      // console.log({ coordinates: coordinates?.length });
      const businessPoints = await getBusinessPoints(
        routeLine,
        selectedCategories,
        range,
        bestOnly,
      );
      if (businessPoints?.data) setBusinessPoints(businessPoints?.data);
      console.log({ businessPoints });
    }
  }

  return true;
}

export async function getNearestGarage(
  point: any,
  setNearestGarage: any,
  setGaragePath: any,
  setRoutes: Function,
  setAllPossibleParkings: Function,
  setBackRoutes: Function,
  setGaragePathToDestinationPath: Function,
  setTemperature: Function,
) {
  const response = await getNearestGarageCoordinates(point);
  const temperature = await fetchTemperature(point);
  const temperatureF = temperature?.current?.temp_f;

  const list_of_nearest: Array<Array<number>> = response?.features.map(
    (item: any, index: number) => {
      return item.geometry.coordinates;
    },
  );
  const pois = list_of_nearest.map((item: any, index: number) => {
    return turf.point(item);
  });
  const pois_fc = turf.featureCollection(pois);
  const startingPoint = turf.point(point.split(',').map((s: any) => parseFloat(s.trim())));
  // const nearest = turf.nearestPoint(startingPoint, pois_fc);
  // setNearestGarage(nearest);
  setTemperature(temperatureF);
  if (temperatureF < 80) {
    setAllPossibleParkings(pois_fc);
  } else {
    getNearestGaragePath(
      point,
      setNearestGarage,
      setGaragePath,
      setRoutes,
      pois_fc,
      setBackRoutes,
      setGaragePathToDestinationPath,
    );
  }
}

const fetchTemperature = async (point: any) => {
  const response = await fetch(
    `${process.env.REACT_APP_WEATHER_API_BASE_URL}/current.json?key=${process.env.REACT_APP_WEATHER_API_KEY}&q=${point}`,
    {
      method: 'GET',
    },
  )
    .then(response => response.json())
    .catch(() => {});

  return response;
};

const getGarageDirections = async (point: any, feature: any, profile: string) => {
  let coordinatesjson = `${apiBaseUrl}/directions/v5/mapbox/${profile}/${point};${feature.geometry.coordinates.toString()}?steps=true&geometries=geojson&access_token=${[
    process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
  ]}`;
  const responseDirection = await fetch(coordinatesjson, {
    method: 'GET',
  }).then(response => response.json());
  return responseDirection;
};

const getNearestGaragePath = async (
  point: any,
  setNearestGarage: any,
  setGaragePath: any,
  setRoutes: Function,
  pois_fc: any,
  setBackRoutes: Function,
  setGaragePathToDestinationPath: Function,
) => {
  let count = 0;
  let _nearest = null;

  for (let _feature of pois_fc.features) {
    let feature: any = _feature;

    const responseDirection = await getGarageDirections(point, feature, 'driving');
    const distance = responseDirection?.routes && responseDirection?.routes[0]?.distance;
    const duration = responseDirection?.routes && responseDirection?.routes[0]?.duration;
    if (!_nearest) {
      _nearest = {
        distance: distance,
        duration: duration,
        index: count,
        routes: responseDirection.routes,
        feature: feature,
      };
    }
    if (duration < _nearest?.duration) {
      _nearest = {
        distance: distance,
        duration: duration,
        index: count,
        routes: responseDirection.routes,
        feature: feature,
      };
    }
    count++;
  }

  // coordinatesjson = `${apiBaseUrl}/directions/v5/mapbox/driving/${point};${nearest.geometry.coordinates.toString()}?steps=true&geometries=geojson&access_token=${[
  //   process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
  // ]}`;
  // const responseDirection = await fetch(coordinatesjson, {
  //   method: 'GET',
  // }).then(response => response.json());

  let routes = _nearest?.routes && Array.isArray(_nearest?.routes) && { ..._nearest.routes[0] };

  if (_nearest && _nearest?.routes) {
    // const line = turf.lineString(_nearest.routes[0].geometry.coordinates);
    const { routeLine } = await generateRouteLine(_nearest.routes[0].legs);

    if (routeLine) setGaragePath(routeLine);
    setRoutes({ ...routes });
    setNearestGarage(_nearest.feature);

    const responseDirectionBack = await getGarageDirections(
      _nearest.feature.geometry.coordinates.toString(),
      turf.point(point.split(',').map((s: any) => parseFloat(s.trim()))),
      'walking',
    );
    let backRoutes = responseDirectionBack?.routes &&
      Array.isArray(responseDirectionBack?.routes) && { ...responseDirectionBack.routes[0] };

    // const line2 = turf.lineString(backRoutes.geometry.coordinates);
    const { routeLine: line2 } = await generateRouteLine(backRoutes.legs);

    if (line2) setGaragePathToDestinationPath(line2);
    setBackRoutes({ ...backRoutes });
  }
};

export const getSelectedGaragePath = async (
  point: any,
  parking: any,
  setNearestGarage: any,
  setGaragePath: any,
  setRoutes: Function,
  setBackRoutes: Function,
  setGaragePathToDestinationPath: Function,
) => {
  let count = 0;
  let _nearest = null;
  const responseDirection = await getGarageDirections(point, parking, 'driving');
  const distance = responseDirection?.routes && responseDirection?.routes[0]?.distance;
  const duration = responseDirection?.routes && responseDirection?.routes[0]?.duration;
  _nearest = {
    distance: distance,
    duration: duration,
    index: count,
    routes: responseDirection.routes,
    feature: parking,
  };

  // coordinatesjson = `${apiBaseUrl}/directions/v5/mapbox/driving/${point};${nearest.geometry.coordinates.toString()}?steps=true&geometries=geojson&access_token=${[
  //   process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
  // ]}`;
  // const responseDirection = await fetch(coordinatesjson, {
  //   method: 'GET',
  // }).then(response => response.json());

  const responseDirectionBack = await getGarageDirections(
    _nearest.feature.geometry.coordinates.toString(),
    turf.point(point.split(',').map((s: any) => parseFloat(s.trim()))),
    'walking',
  );
  let backRoutes = responseDirectionBack?.routes &&
    Array.isArray(responseDirectionBack?.routes) && { ...responseDirectionBack.routes[0] };

  let routes = _nearest?.routes && Array.isArray(_nearest?.routes) && { ..._nearest.routes[0] };
  if (_nearest && _nearest?.routes) {
    const { routeLine } = await generateRouteLine(_nearest.routes[0].legs);

    // const line = turf.lineString(_nearest.routes[0].geometry.coordinates);
    if (routeLine) setGaragePath(routeLine);
    setRoutes({ ...routes });
    setNearestGarage(_nearest.feature);
    // const line2 = turf.lineString(backRoutes.geometry.coordinates);
    const { routeLine: line2 } = await generateRouteLine(backRoutes.legs);
    if (line2) setGaragePathToDestinationPath(line2);
    setBackRoutes({ ...backRoutes });
  }
};

export const getAllCategories = async () => {
  const api = `${tripsBaseURL}/yelp/categories`;
  const response = await fetch(api, {
    method: 'GET',
  }).then(response => response.json());
  return response;
};

export const getBusinessPoints = async (
  routeLine: any,
  categories: Array<string>,
  range: number,
  bestOnly: boolean,
) => {
  const options = {
    tolerance: 0.0001, // Simplification tolerance (smaller tolerance means higher precision)
  };
  const simplifiedGeometry = turf.simplify(routeLine, options);
  let coordinates = simplifiedGeometry?.geometry.coordinates;
  console.log({ coordinates });
  const body = { coordinates, categories, radius: range, bestOnly };
  // const _api = `${tripsBaseURL}/yelp/getPointersStatic`;
  // axios
  //   .get(_api)
  //   .then(response => {
  //     console.log('Data sent:', response.data, '==================');
  //     return response.data;
  //   })
  //   .catch(error => {
  //     console.error('Error:', error, '==================');
  //     return error;
  //   });

  // await fetch(_api, {
  //   method: 'GET',
  // }).then(response => response.json());
  // const response = await fetch(api, {
  //   method: 'POST',
  //   body: JSON.stringify(body),
  //   headers: {
  //     'Content-Type': 'application/json',
  //   },
  // }).then(response => response.json());

  const api = `${tripsBaseURL}/yelp/getPointers`;
  const headers = { 'Content-Type': 'application/json' };

  const data = await axios
    .post(api, body, { headers: headers })
    .then(response => {
      console.log('Data sent:', response.data, '==================');
      return response.data;
    })
    .catch(error => {
      console.error('Error:', error, '==================');
    });
  console.log({ data });
  return data;
};
