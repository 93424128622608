import React from 'react';
import Button from '@mui/material/Button';

interface SuccesButtonProps {
  children: React.ReactNode;
}

export const SuccesButton: React.FC<SuccesButtonProps> = ({ children }) => {
  return (
    <Button variant="contained" 
        sx={{
            backgroundColor: '#1988cb',
            borderRadius: '42px',
            padding: '0.5rem 2rem',
            color: '#ffffff',
            fontSize: '15px',
            '&:hover': {
            backgroundColor: '#156b8a',
            opacity: 0.9,
            },
            '&.Mui-active': {
            backgroundColor: '#0f455c',
            },
        }}
    >
      {children}
    </Button>
  );
};

