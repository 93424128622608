import * as React from 'react';
import { Box, Typography, List, ListItem, ListItemButton, ListItemIcon } from '@mui/material';
import { FormatDuration, FormatDistance } from '../../utils';

export const Instructions = ({
  routes,
  mapLeft,
  mapRight,
  parkingRoutes,
  backRoutes,
  coordinates_resp,
  iterateArray,
  stopAutomation,
}: any) => {
  const instructions = routes?.legs;
  const parkingInstructions = parkingRoutes?.legs;
  const backInstructions = backRoutes?.legs;
  const handleNavigation = (location: Array<number>) => {
    mapLeft.current.flyTo({
      center: location,
    });
    mapRight.current.flyTo({
      center: location,
    });
  };

  const [play, setPlay] = React.useState(false);
  const [plaParkingInstructions, setPlaParkingInstructions] = React.useState(false);
  const [playBackInstructions, setPlayBackInstructions] = React.useState(false);
  const [selectedInstruction, setSelectedInstruction] = React.useState<any>(null);
  // const [stop, setStop] = React.useState(false);
  const stop = React.useRef(false);

  const resetState = () => {
    setPlay(false);
    setPlayBackInstructions(false);
    setPlaParkingInstructions(false);
    setSelectedInstruction(null);
  };

  async function iterateNestedArray(instructions: Array<any>, type: string) {
    let outerIndex = 0;
    async function iterateInnerArray(innerArray: any) {
      let innerIndex = 0;
      console.log({ innerArray, length: innerArray.length }, 'type');

      async function processNextElement() {
        if (innerIndex < innerArray.length) {
          console.log(innerArray[innerIndex]);
          let step = innerArray[innerIndex];

          if (!stopAutomation.current) {
            // handleNavigation(step?.maneuver?.location);
            console.log({ duration: step?.duration }, '============');
            if (innerArray[innerIndex + 1]) {
              const nextStep = innerArray[innerIndex + 1];
              // animateMarker(step?.maneuver?.location, nextStep?.maneuver?.location);
            }
            setSelectedInstruction({
              type: type,
              innerIndex: innerIndex,
              outerIndex: outerIndex,
            });
          } else {
            resetState();
          }

          innerIndex++;
          console.log('if', { innerIndex });
          if (stopAutomation.current) {
            resetState();
            return;
          } else {
            await setTimeout(async () => {
              await processNextElement();
            }, 3000); // Wait for 3 seconds before the next inner iteration
          }
        } else {
          outerIndex++;
          console.log('else', { outerIndex, length: instructions.length });

          if (outerIndex < instructions.length) {
            if (stopAutomation.current) {
              resetState();
              return;
            } else {
              await setTimeout(async function () {
                const instruction = instructions[outerIndex]?.steps;
                await iterateInnerArray(instruction);
                // Start the next inner iteration after a delay
              }, 3000); // Wait for 3 seconds before the next outer iteration
            }
          } else {
            if (play && !stopAutomation.current) {
              if (parkingInstructions) {
                setPlaParkingInstructions(true);
                setPlay(false);
              } else {
                resetState();
              }
            } else if (plaParkingInstructions && !stopAutomation.current) {
              if (backInstructions) {
                setPlayBackInstructions(true);
                setPlaParkingInstructions(false);
              } else {
                resetState();
              }
            } else {
              setPlayBackInstructions(false);
              resetState();
            }

            return;
          }
        }
      }
      if (stopAutomation.current) {
        resetState();
        return;
      } else {
        await processNextElement();
      } // Start processing inner array elements
    }

    if (stopAutomation.current) {
      resetState();
      return;
    } else {
      await iterateInnerArray(instructions[outerIndex]?.steps);
    } // Start processing the first inner array
  }

  React.useEffect(() => {
    if (play) {
      stopAutomation.current = false;
      // iterateNestedArray(instructions, 'A2B'); // Start the iteration
      iterateArray(coordinates_resp?.geometry.coordinates, () => setPlay(false));
    }
  }, [play]);

  React.useEffect(() => {
    if (plaParkingInstructions && parkingInstructions) {
      iterateNestedArray(parkingInstructions, 'B2P'); // Start the iteration
    }
  }, [plaParkingInstructions, parkingInstructions]);

  React.useEffect(() => {
    if (playBackInstructions && backInstructions) {
      iterateNestedArray(backInstructions, 'P2B'); // Start the iteration
    }
  }, [playBackInstructions, backInstructions]);

  React.useEffect(() => {
    setPlay(false);
    setPlaParkingInstructions(false);
    setPlayBackInstructions(false);
    setSelectedInstruction(null);
  }, [stopAutomation.current]);

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          bgcolor: '#3F50B5',
          py: 1,
          px: 1,
        }}
      >
        <Typography component='span' sx={{ fontWeight: 'bold', color: 'white' }}>
          {routes?.duration ? FormatDuration(routes?.duration) : '0 min'}
        </Typography>
        <Typography
          component='span'
          sx={{ opacity: 0.5, ml: 3, fontWeight: 'bold', color: 'white' }}
        >
          {routes?.distance ? FormatDistance(routes?.distance) : '0 km'}
        </Typography>
        <Typography component='span' sx={{ ml: 3 }}>
          {!play && !playBackInstructions && !plaParkingInstructions ? (
            <img
              onClick={() => setPlay(true)}
              style={{ cursor: 'pointer', marginTop: '5px' }}
              alt='play'
              src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAiUlEQVR4nNXUvQ2DQAyGYdKkZYSMkAWgYgZmYYXcFtSswAJITMAOtJSf30gX6VjgKy6W3Lh4JMs/TfNXgZSQdmBwgQsR5JRW4O0DI6PKNXh5wCjwhfQBWg8YBT6BCXh6wCjwAYzAwwPe8Ab0PvCHBtJcKajcclfRUORaG7kWW87Tk+c5JOv7qjK+JMZL+lB9RLYAAAAASUVORK5CYII='
            />
          ) : (
            // <></>
            <img
              onClick={() => {
                stopAutomation.current = true;
              }}
              style={{ cursor: 'pointer', marginTop: '5px' }}
              alt='stop'
              src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAATUlEQVR4nO2TMQrAMAwD9f8fpM1Te1EIdCgU6oRm9IE2c4OQpWQ7hmq43Jo/M27gnBHGsodUoXBWdkcpfJEd+v9s2D1sOBZer4TCRKt0vDM6/TSp0HEAAAAASUVORK5CYII='
            />
          )}
        </Typography>
      </Box>

      <List>
        {instructions?.map((item: any, _index: number) => {
          return item?.steps?.map((step: any, index: number) => {
            var icon = step?.maneuver?.modifier
              ? step?.maneuver?.modifier.replace(/\s+/g, '-').toLowerCase()
              : step?.maneuver?.type.replace(/\s+/g, '-').toLowerCase();
            if (step?.maneuver?.type === 'arrive' || step?.maneuver?.type === 'depart') {
              icon = step?.maneuver?.type;
            }
            if (step?.maneuver?.type === 'roundabout' || step?.maneuver?.type === 'rotary') {
              icon = 'roundabout';
            }
            return (
              <ListItem
                disablePadding
                key={index}
                sx={{
                  bgcolor:
                    selectedInstruction?.innerIndex === index &&
                    selectedInstruction?.outerIndex === _index &&
                    selectedInstruction?.type === 'A2B'
                      ? '#A3A3A550'
                      : 'white',
                }}
              >
                <ListItemButton
                  sx={{ py: 1 }}
                  onClick={() => handleNavigation(step?.maneuver?.location)}
                >
                  <ListItemIcon>
                    <span className={`directions-icon  directions-icon-${icon}`} />
                    <Typography component='span' sx={{ opacity: 0.5, mt: 4, fontSize: '10px' }}>
                      {step?.distance ? FormatDistance(step?.distance) : '0 km'}
                    </Typography>
                  </ListItemIcon>

                  <Typography variant='body1' sx={{ fontSize: '14px', mt: -3 }}>
                    {step?.maneuver?.instruction}
                  </Typography>
                </ListItemButton>
              </ListItem>
            );
          });
        })}
        {parkingInstructions ? (
          <>
            {' '}
            <Box sx={{ display: 'flex', justifyContent: 'center', py: 1 }}>
              <Typography
                component='div'
                sx={{ fontWeight: 'bold', textAlign: 'center', color: '#00e673' }}
              >
                B to Parking (Driving)
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                bgcolor: '#00e673',
                py: 1,
                mb: 1,
                px: 1,
              }}
            >
              <Typography component='span' sx={{ fontWeight: 'bold', color: 'white' }}>
                {parkingRoutes?.duration ? FormatDuration(parkingRoutes?.duration) : '0 min'}
              </Typography>
              <Typography
                component='span'
                sx={{ opacity: 0.5, ml: 3, fontWeight: 'bold', color: 'white' }}
              >
                {parkingRoutes?.distance ? FormatDistance(parkingRoutes?.distance) : '0 km'}
              </Typography>
            </Box>
            {parkingInstructions?.map((item: any, _index: number) => {
              return item?.steps?.map((step: any, index: number) => {
                var icon = step?.maneuver?.modifier
                  ? step?.maneuver?.modifier.replace(/\s+/g, '-').toLowerCase()
                  : step?.maneuver?.type.replace(/\s+/g, '-').toLowerCase();
                if (step?.maneuver?.type === 'arrive' || step?.maneuver?.type === 'depart') {
                  icon = step?.maneuver?.type;
                }
                if (step?.maneuver?.type === 'roundabout' || step?.maneuver?.type === 'rotary') {
                  icon = 'roundabout';
                }
                return (
                  <ListItem
                    disablePadding
                    key={index}
                    sx={{
                      bgcolor:
                        selectedInstruction?.innerIndex === index &&
                        selectedInstruction?.outerIndex === _index &&
                        selectedInstruction?.type === 'B2P'
                          ? '#A3A3A550'
                          : 'white',
                    }}
                  >
                    <ListItemButton
                      sx={{ py: 1 }}
                      onClick={() => handleNavigation(step?.maneuver?.location)}
                    >
                      <ListItemIcon>
                        <span className={`directions-icon  directions-icon-${icon}`} />
                        <Typography component='span' sx={{ opacity: 0.5, mt: 4, fontSize: '10px' }}>
                          {step?.distance ? FormatDistance(step?.distance) : '0 km'}
                        </Typography>
                      </ListItemIcon>

                      <Typography variant='body1' sx={{ fontSize: '14px', mt: -3 }}>
                        {step?.maneuver?.instruction}
                      </Typography>
                    </ListItemButton>
                  </ListItem>
                );
              });
            })}
          </>
        ) : (
          <></>
        )}

        {backInstructions ? (
          <>
            {' '}
            <Box sx={{ display: 'flex', justifyContent: 'center', py: 1 }}>
              <Typography
                component='div'
                sx={{ fontWeight: 'bold', textAlign: 'center', color: 'red' }}
              >
                Parking to B (Walking)
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                bgcolor: 'red',
                py: 1,
                mb: 1,
                px: 1,
              }}
            >
              <Typography component='span' sx={{ fontWeight: 'bold', color: 'white' }}>
                {backRoutes?.duration ? FormatDuration(backRoutes?.duration) : '0 min'}
              </Typography>
              <Typography
                component='span'
                sx={{ opacity: 0.5, ml: 3, fontWeight: 'bold', color: 'white' }}
              >
                {backRoutes?.distance ? FormatDistance(backRoutes?.distance) : '0 km'}
              </Typography>
            </Box>
            {backInstructions?.map((item: any, _index: number) => {
              return item?.steps?.map((step: any, index: number) => {
                var icon = step?.maneuver?.modifier
                  ? step?.maneuver?.modifier.replace(/\s+/g, '-').toLowerCase()
                  : step?.maneuver?.type.replace(/\s+/g, '-').toLowerCase();
                if (step?.maneuver?.type === 'arrive' || step?.maneuver?.type === 'depart') {
                  icon = step?.maneuver?.type;
                }
                if (step?.maneuver?.type === 'roundabout' || step?.maneuver?.type === 'rotary') {
                  icon = 'roundabout';
                }
                return (
                  <ListItem
                    disablePadding
                    key={index}
                    sx={{
                      bgcolor:
                        selectedInstruction?.innerIndex === index &&
                        selectedInstruction?.outerIndex === _index &&
                        selectedInstruction?.type === 'P2B'
                          ? '#A3A3A550'
                          : 'white',
                    }}
                  >
                    <ListItemButton
                      sx={{ py: 1 }}
                      onClick={() => handleNavigation(step?.maneuver?.location)}
                    >
                      <ListItemIcon>
                        <span className={`directions-icon  directions-icon-${icon}`} />
                        <Typography component='span' sx={{ opacity: 0.5, mt: 4, fontSize: '10px' }}>
                          {step?.distance ? FormatDistance(step?.distance) : '0 km'}
                        </Typography>
                      </ListItemIcon>

                      <Typography variant='body1' sx={{ fontSize: '14px', mt: -3 }}>
                        {step?.maneuver?.instruction}
                      </Typography>
                    </ListItemButton>
                  </ListItem>
                );
              });
            })}
          </>
        ) : (
          <></>
        )}
      </List>
    </Box>
  );
};
