import * as React from 'react';
import {
    Home as HomeIcon,
    Info as InfoIcon,
    ContactMail as ContactMailIcon,
  } from '@mui/icons-material';

export const navItems: NavItem[] = [
  { text: 'Home', icon: React.createElement(HomeIcon) },
  { text: 'About', icon: React.createElement(InfoIcon) },
  // { text: 'Contact', icon: React.createElement(ContactMailIcon) },
];

export const footerLinks = [
  {
      heading: "Navigate Maps",
      values: ["Try Navigate Maps", "Documentation", "Help Center"],
  },
  {
      heading: "Contributors",
      values: ["Become a Contributor", "Navigate Rewards", "Navigate Marketplace"],
  },
  {
      heading: "Legal",
      values: ["Terms of Use", "Privacy Policy", "Cookie Policy"],
  },
  {
      heading: "Get In Touch",
      values: ["Contact Us", "Support"],
  },
];

export const officialEmail = '';
export const twitterAccountURL = '';
export const docsURL= ''; 
export const discordURL= '';
export const HELP_CENTER_URL= '';
export const FB_URL= '';
export const INSTA_URL= '';
export const LINKEDIN_URL= '';
export const marketplaceURL= '';
export const mapsAlphaURL= '';
export const mediumURL= '';
export const baseURL= '';
